import React, { memo } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
interface ChatItemProps {
    user: string;
    content: string;
}
export default memo(({ user, content }: ChatItemProps) => {
    return (
        <ChatItemWrap>
            <span>{user}:</span>
            <span>{content}</span>
        </ChatItemWrap>
    )
})

const ChatItemWrap = memo(styled.div`
    display:flex;
    /* flex-wrap:wrap;
    align-items:flex-start; */
    margin-bottom:0.9rem;
    span{
        &:first-child{
            color:${Colors.gray2};
            font-weight:bold;
            margin-right:1rem;
            display: block;
            float: left;
        }
        &:nth-child(2){
            display: block;
            float: right;
        }
    }
`)