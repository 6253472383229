import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
import Activities from 'components/common/activities'
import LeaderBoard from 'components/common/leaderBoard'
import { HeaderContext } from 'contexts/headerContext'
export default () => {
    const { headerState: { showWindow }, changeShowWindow } = useContext(HeaderContext)
    return (
        <AsideLeaderBoardWrap show={showWindow === 'leaderBoard'}>
            <div className={`alb_inner ${showWindow === 'leaderBoard' ? 'show' : ''}`}>
                <div className="aside_inner">
                    <LeaderBoard />
                    <div className="custom_left_aside_height">
                        <Activities />
                    </div>

                </div>
            </div>
            <div className={`blur_bg ${showWindow === 'leaderBoard' ? 'show' : ''}`}
                onClick={() => changeShowWindow(showWindow === 'leaderBoard' ? '' : 'leaderBoard')}
            />
        </AsideLeaderBoardWrap>
    )
}

const AsideLeaderBoardWrap = memo(styled.div`
    .alb_inner{
        position:fixed;
        z-index:2;
        width:60vw;
        height:calc(100vh - 70px);
        bottom:0;
        left:0;
        transition: all 0.4s cubic-bezier(0.06, 0.33, 0, 0.99) 0s;
        transform: translateX(-60vw);
        background:${Colors.black2};
        overflow:auto;
        @media (max-width:767px){
            width:100vw;
            transform: translateX(-100vw);
            height:calc(100vh - 60px);
        }
        @media (max-width:480px){
            height:calc(100vh - 50px);
        }
        &.show{
            transform: translateX(0);
        }
        .custom_left_aside_height{
            height:50vh;
        }
    }
`)