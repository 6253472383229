import React, { memo, useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import i18n from 'i18n-js'
import ActivitiesItem from 'components/common/activities/activitiesItem'
import { SiteContext } from 'contexts/siteContext'
import { useApolloClient, gql } from '@apollo/client'
import { TronContext } from 'contexts/tronWeb'

const GET_ACTIVITY = gql`
    query getActivity($quantity: Int!){
        dice_activities_get(quantity:$quantity){
            address
            payout
            updatedAt
        }
    }
`

const ACTIVITY_SUBSCRIPTION = gql`
subscription{
    dice_activities_subscription{
        address
        payout
        updatedAt
    }
}
`
export default () => {
    const { siteState: { locale } } = useContext(SiteContext)
    const { action, tronWeb } = useContext(TronContext)
    const client = useApolloClient()
    const [activities, setActivities] = useState([
        { type: 'megabit', content: 'New game is comming' }
    ] as any)
    const getActivity = () => {
        try {
            const query = client.watchQuery({
                query: GET_ACTIVITY,
                variables: {
                    quantity: 10
                }
            })
            const querySub = query.subscribe(({ data: { dice_activities_get } }) => {
                // console.log('dice_activities_get', dice_activities_get)
                if (dice_activities_get) {
                    let list = [] as any
                    for (let i = 0; i < dice_activities_get.length; i++) {
                        list.push({
                            type: 'megabit',
                            content: i18n.t('anounceActivity', {
                                addr: `${dice_activities_get[i].address.slice(0, 4)}...${dice_activities_get[i].address.slice(-5)}`,
                                amount: dice_activities_get[i].payout / 10 ** 6
                            })
                        })
                    }
                    setActivities(list)
                }
            })
            return querySub
        } catch (error) {
            console.log('error get activity', error)
        }
    }
    useEffect(() => {
        getActivity()
    }, [])

    const activitySubscription = () => {
        const sub = client.subscribe({
            query: ACTIVITY_SUBSCRIPTION
        })
        const activitySubs = sub.subscribe(({ data: { dice_activities_subscription } }) => {
            let ping = setTimeout(() => getActivity(), 500);
            return () => {
                clearTimeout(ping);
            };
        })
        return () => activitySubs.unsubscribe()
    }

    useEffect(() => {
        activitySubscription()
    }, [])
    return (
        <ActivitiesWrap>
            <div className="block_label">
                <span>{i18n.t('activities')}</span>
            </div>
            <div className="block_content">
                <div className="activities_inner">
                    {activities.map((item, index) => {
                        return (
                            <ActivitiesItem key={index} type={item.type} content={item.content} />
                        )
                    })}
                </div>
            </div>
        </ActivitiesWrap>
    )
}

const ActivitiesWrap = memo(styled.div`
    width: 100%;
    height: 100%;
    overflow:hidden;
    .block_content{
        height: calc(100% - 50px);
        .activities_inner{
            height:100%;
            overflow-y:scroll;
        }
    }
`)