import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import AsideLeaderBoard from 'components/common/header/leftAside/asideLeaderBoard'
import { HeaderContext } from 'contexts/headerContext'
const ldImg = require('assets/images/leaderboard.svg')
export default () => {
    const { headerState: { showWindow }, changeShowWindow } = useContext(HeaderContext)
    return (
        <LeaderBoardWrap>
            <img src={ldImg} alt="" onClick={() => changeShowWindow(showWindow === 'leaderBoard' ? '' : 'leaderBoard')} />
            {(window as any).innerWidth < 1200 ?
                <AsideLeaderBoard />
                :
                null
            }
        </LeaderBoardWrap>
    )
}

const LeaderBoardWrap = memo(styled.div`
    margin-right:1rem;
    position:relative;
    >img {
        width:35px;
        height:25px;
        display:none;
        @media (max-width:1199px){
            display:block;
        }
    }
`)