import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
const logoImg = require('assets/images/logo.svg')
const logoIconImg = require('assets/images/icon-logo.svg')
export default memo(() => {
    // let currentPath = useLocation();
    return (
        <LogoWrap>
            <Link to="/">
                <img src={require('assets/images/logo.svg')} alt="Luminous Logo" className="logo" />
                <img src={require('assets/images/icon-logo.svg')} alt="Luminous Logo" className="icon_logo" />
            </Link>
        </LogoWrap>
    )
})

const LogoWrap = memo(styled.div`
    width:100%;
    height:100%;
    a{
        @media (max-width:767px){
            margin: 0 0.5rem 0 0;
            display: block;
        }
    }
    img{
        height:60px;
        &.logo{
            display:block;
        }
        &.icon_logo{
            display:none;
        }
        @media (max-width:767px){
            height:35px;
            &.logo{
                display:none;
            }
            &.icon_logo{
                display:block;
            }
        }
        @media (max-width:480px){
            height:20px;
        }
    }
`)