import React, { useReducer } from "react";
const initState = {
    showWindow: "",
};
const initHeaderContext = {
    headerState: initState,
    changeShowWindow: (name: string) => { },
};
const HeaderContext = React.createContext(initHeaderContext);
export default ({ children }: any) => {
    const initHeaderState = (state: any) => state;
    const headerReducer = (preState: any, action: any) => {
        switch (action.type) {
            case "init": {
                return action.initState;
            }
            case "changeShowWindow": {
                return { ...preState, showWindow: action.showWindow };
            }
            default:
                return preState;
        }
    };
    const [headerState, dispatchHeader] = useReducer(
        headerReducer,
        initState,
        initHeaderState
    );

    const changeShowWindow = (name: string) => {
        dispatchHeader({
            type: "changeShowWindow",
            showWindow: name
        });
    };
    return (
        <HeaderContext.Provider
            value={{
                headerState,
                changeShowWindow
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};
export { HeaderContext };
