

import React, { memo } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
import Chat from 'components/common/chat'
interface AsideChatProps {
    showWindow: string;
    setShow: () => void
}
export default ({ showWindow, setShow }: AsideChatProps) => {
    return (
        <AsideChatWrap show={showWindow === 'chat'}>
            <div className={`ac_inner ${showWindow === 'chat' ? 'show' : ''}`}>
                <div className="aside_inner">
                    <Chat />
                </div>
            </div>
            <div className={`blur_bg ${showWindow === 'chat' ? 'show' : ''}`} onClick={() => setShow()} />
        </AsideChatWrap>
    )
}

const AsideChatWrap = memo(styled.div`
    width:100%;
    height:100%;
    .ac_inner{
        position:fixed;
        z-index:2;
        width:60vw;
        height:calc(100vh - 70px);
        bottom:0;
        right:0;
        transition: all 0.4s cubic-bezier(0.06, 0.33, 0, 0.99) 0s;
        transform: translateX(160vw);
        background:${Colors.black2};
        overflow:auto;
        @media (max-width:767px){
            width:100vw;
            transform: translateX(100vw);
            height:calc(100vh - 60px);
        }
        @media (max-width:480px){
            height:calc(100vh - 50px);
        }
        &.show{
            transform: translateX(0);
            @media (max-width:767px){
                transform: translateX(0);
            }
        }
        .aside_inner{
            height:calc(100% - 4rem);
            @media (max-width:767px){
                height:calc(100% - 2rem);
            }
        }
    }
`)