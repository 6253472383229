import React, { memo, useRef } from "react";
import styled from "styled-components/macro";
import MainRoutes from "../../router";
import Header from 'components/common/header';
import Colors from "constants/Colors";
import Footer from 'components/common/footer'
export default () => {
    const bodyRef = useRef(null)
    return (
        <WrapBody ref={bodyRef}>
            <Header bodyRef={bodyRef}/>
            <MainRoutes />
            <Footer />
            {/* <ReactTooltip /> */}
        </WrapBody>
    );
};
const WrapBody = memo(styled.div`
    width:100%;
    height:100%;
    background-color: ${Colors.black1};
    overflow-x:hidden;
`);