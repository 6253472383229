import React, { useState, useEffect } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, gql, split, NormalizedCacheObject } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/link-ws";
import Loading from "components/common/loadingScreen";
import { config } from "contexts/apolloConfig";
import { setContext } from "apollo-link-context";

export default ({ children }) => {
    let initClient: ApolloClient<NormalizedCacheObject> | undefined = undefined;
    const [client, setClient] = useState(initClient);
    const setupApollo = async token => {
        const cache = new InMemoryCache();
        const httpLink = new HttpLink({
            uri: config.graphql,
            credentials: "same-origin"
        });

        const wsLink = new WebSocketLink({
            uri: config.wsGraphql,
            options: {
                reconnect: true,
                connectionParams: {
                    authToken: token
                }
            }
        });

        const authLink = setContext((_, { headers }) => {
            let obj = { ...headers };
            return {
                headers: obj
            };
        });

        // await persistCache({
        //   cache,
        //   storage: AsyncStorage,
        //   trigger: "background",
        //   maxSize: false
        // });

        const link = split(
            // split based on operation type
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === "OperationDefinition" &&
                    definition.operation === "subscription"
                );
            },
            wsLink as any,
            authLink.concat(httpLink as any) as any
        );
        setClient(new ApolloClient({ cache, link }) as any);
    }

    const init = async () => {
        setupApollo(undefined);
    };
    useEffect(() => {
        init();
    }, []);

    return !!client ? (
        <ApolloProvider client={client as any}>
            {children}
        </ApolloProvider>
    ) : (
        <Loading />
    )

};
