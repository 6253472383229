import React, { memo, useContext } from 'react'
import styled, { css } from 'styled-components'
import i18n from 'i18n-js'
import Colors from 'constants/Colors'
import { SiteContext } from 'contexts/siteContext'
interface LangItemProps {
    data: string;
    locale: string;
    index: number;
    setActive: () => void
}
export default memo(({ data, locale, index, setActive }: LangItemProps) => {
    const { changeLocale } = useContext(SiteContext)
    return (
        <LangItemWrap index={index} locale={locale} data={data} onClick={() => {
            if (data !== locale) {
                changeLocale(data)
            }
            setActive()
        }}>
            <div className="li_left">
                <div className="lang_img">
                    <img src={require(`assets/images/${data}.svg`)} alt="" />
                </div>
                <span>{i18n.t(`${data}Name`)}</span>
            </div>
            <div className={`lang_status ${data === locale ? 'current' : ''}`} />
        </LangItemWrap>
    )
})

const LangItemWrap = memo(styled.div`
    padding:0 1rem;
    height:65px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    transition:all .1s ease-in-out;
    cursor:pointer;
    ${(props: any) => props.data === props.data.locale && css`background-color:${Colors.black3};`}
    &:hover{
        background-color:${Colors.black3};
    }
    .li_left{
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        .lang_img{
            width:35px;
            height:35px;
            position:relative;
            border-radius:50%;
            overflow:hidden;
            margin-right:12px;
            img{
                height:35px;
                max-width:inherit;
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
            }
        }
    }
    .lang_status{
        width:8px;
        height:8px;
        border-radius:50%;
        background-color:${Colors.positive};
        visibility:hidden;
        &.current{
            visibility:visible;
        }
    }
`)