import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import LangItem from 'components/common/language/langItem'
import Colors from 'constants/Colors'
import CurrentLang from 'components/common/language/currentLang'
import { SiteContext } from 'contexts/siteContext'
import { HeaderContext } from 'contexts/headerContext'

export default () => {
    const listLang = ['en']
    const { siteState: { locale } } = useContext(SiteContext)
    const { headerState: { showWindow }, changeShowWindow } = useContext(HeaderContext)
    const setActive = () => {
        changeShowWindow('')
    }
    return (
        <LanguageWrap listLang={listLang} active={showWindow === 'language'}>
            <div className="current_lang" onClick={() => changeShowWindow(showWindow === 'language' ? '' : 'language')}>
                <CurrentLang locale={locale} />
                <div className="arrow_down" />
            </div>
            <div className={`lang_drop ${showWindow === 'language' ? 'collapsed' : ''}`}>
                {listLang.map((item, index) => {
                    return <LangItem
                        key={index}
                        data={item}
                        locale={locale}
                        index={index}
                        setActive={setActive}
                    />
                })}
            </div>
        </LanguageWrap>
    )
}

const LanguageWrap = memo(styled.div`
    position:relative;
    margin-right:calc(100vw * 0.036);
    .current_lang{
        display:flex;
        align-items:center;
        cursor:pointer;
    }
    .lang_drop{
        height:0;
        position:absolute;
        top:calc(100% + 15px);
        left:-50%;
        width:400%;
        max-width:250px;
        background:${Colors.black2};
        transition:height .1s ease-in-out;
        overflow:hidden;
        border-radius:5px;
        &.collapsed{
            height:${(props: any) => props.listLang.length * 65}px;
        }
        @media (max-width:767px){
            left:auto;
            right:-20px;
        }
    }
    &:after{
        content:'';
        display:${(props: any) => props.active ? 'block' : 'none'};
        position:absolute;
        z-index:1;
        top:calc(100% + 5px);
        left:9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-top-width: 0px;
        border-right-width: 9px;
        border-bottom-width: 10px;
        border-left-width: 9px;
        border-bottom-color: ${Colors.black2};
        border-right-color: transparent;
        border-top-color: transparent;
        border-left-color: transparent;
    }
`)