import React, { ReactNode, useState, useEffect } from "react";
import TronWeb from "tronweb";
import { node, contract } from "./tronConfig";
import * as qs from "query-string";
// import LoadingScreen from "components/core/loadingScreen";
interface IProps {
    children: ReactNode;
}

const tronWeb = new TronWeb({
    fullNode: node,
    solidityNode: node,
    eventServer: node,
    privateKey:
        "EC60C070F98F768400F45CF0674092998B716E7B0D5C26F31E30B2E1A3785B7D",
});

const TronContext = React.createContext({
    connectTronLink: () => { },
    data: {
        ready: false,
        lumi: undefined as any,
        member: undefined as any
    },
    action: {
        isConnect: false,
        address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        lumi: undefined as any,
        member: undefined as any,
        dice: undefined as any
    },
    ref: contract.memberAddress,
    tronWeb
});




const waitTron = () => {
    // try {
        return new Promise((res) => {
            let attempts = 0,
                maxAttempts = 20;
            const checkTron = () => {
                if (
                    (window as any).tronWeb &&
                    (window as any).tronWeb.defaultAddress.base58
                ) {
                    res(true);
                    return;
                }
                attempts++;
                if (attempts >= maxAttempts) {
                    res(false);
                    return;
                }
                setTimeout(checkTron, 100);
            };
            checkTron();
        });
    // } catch (error) {
    //     console.log('KKK', error)
    // }
};

const initStatic = async () => {
    const [lumi, member] = await Promise.all([
        tronWeb.contract().at(contract.token),
        tronWeb.contract().at(contract.memberAddress)
    ])
    return {
        ready: true,
        lumi,
        member
    };
};
const initAction = async () => {
    let tronExist = await waitTron();
    if (tronExist) {
        const [lumi, member, dice] = await Promise.all([
            (window as any).tronWeb.contract().at(contract.token),
            (window as any).tronWeb.contract().at(contract.memberAddress),
            (window as any).tronWeb.contract().at(contract.diceAddress)
        ])
        return {
            isConnect: true,
            address: (window as any).tronWeb.defaultAddress.base58,
            lumi,
            member, dice
        };
    } else {
        return {
            isConnect: false,
            address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" as any,
            lumi: undefined as any,
            member: undefined as any,
            dice: undefined as any
        };
    }
};

export default ({ children }: IProps) => {
    const [ref, setRef] = useState(contract.memberAddress)
    const [tronStatic, setTronStatic] = useState({
        ready: false,
        lumi: undefined as any,
        member: undefined as any,
    });

    const getRef = async () => {
        const parsed = qs.parse(window.location.search);
        // console.log('parsed', parsed)
        if (parsed.r) {
            if (tronWeb.isAddress(parsed.r)) {
                if (window.localStorage) {
                    window.localStorage.setItem("ref", (parsed as any).r);
                }
                setRef((parsed as any).r);
            }
        } else {
            if (window.localStorage) {
                let refLocal = window.localStorage.getItem("ref");
                if (tronWeb.isAddress(refLocal)) {
                    setRef(refLocal as string);
                }
            }
        }
    };

    useEffect(() => {
        initStatic().then((dataTron) => setTronStatic(dataTron));
        getRef()
    }, []);

    const [tronAction, setTronAction] = useState({
        isConnect: false,
        address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" as any,
        lumi: undefined as any,
        member: undefined as any,
        dice: undefined as any,
    });
    useEffect(() => {
        initAction().then((actionTron) => setTronAction(actionTron));
    }, []);

    const connectTronLink = () => {
        initAction().then((actionTron) => setTronAction(actionTron));
    };

    useEffect(() => {
        if (tronAction.isConnect) {
            window.addEventListener("message", function (e) {
                if (e.data.message?.action === "setAccount") {
                    setTronAction({
                        ...tronAction,
                        address: e.data.message.data.address,
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tronAction.isConnect]);
    return (
        <TronContext.Provider
            value={{
                connectTronLink,
                data: tronStatic,
                action: tronAction,
                ref,
                tronWeb
            }}
        >
            {children}
        </TronContext.Provider>
    );
};

export { TronContext, tronWeb };
