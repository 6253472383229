import React, { memo } from 'react'
import i18n from 'i18n-js'
import Colors from 'constants/Colors'
import styled from 'styled-components'
const sendImg = require('assets/images/send-mes.svg')
export default () => {
    return (
        <ChatSendWrap>
            <input type="text" placeholder={i18n.t('writeYourMessage')}
            // onChange={(e) => { setChatContent(e.target.value) }}
            />
            <div className="cs_button">
                <img src={sendImg} alt="" />
            </div>
        </ChatSendWrap>
    )
}

const ChatSendWrap = memo(styled.div`
    
    position:relative;
    input{
        width:calc(100% - 5rem);
        padding: 0 4rem 0 1rem;
        border:solid 1px ${Colors.black3};
        border-radius:5px;
        background-color:${Colors.black2};
        height:40px;
        color:${Colors.white};
        font-weight:300;
        &::placeholder{
            color:${Colors.gray2};
        }
        &:focus{
            animation: glow 800ms ease-out infinite alternate;
            @keyframes glow {
                0% {
                    border-color: #393;
                    box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
                }	
                100% {
                    border-color: #6f6;
                    box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000;
                }
            }
        }
        }
    }
    .cs_button{
        position:absolute;
        top:50%;
        right:1rem;
        transform:translate(0, -50%);
        cursor:pointer;
        img{
            width:20px;
            height:20px;
        }
    }
`)