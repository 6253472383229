export default {
    white: '#ffffff',
    black: '#000000',
    black1: '#0F212E',
    black2: '#1A2C38',
    black3: '#223948',
    black4: '#2F4553',
    red: '#ff3b30',
    red1: '#DF4141',
    danger: '#ff0000',
    green: '#10d078',
    green1: '#ADEF37',
    positive: '#8FB846',
    blue: '#16c3ef',
    blue1: '#4D7CFB',
    blue2: '#027BFB',
    blue3: '#0064CE',
    blue4: '#6AB3FF',
    blue5: '#BFE5FE',
    blue6: '#BFE5FF',
    gray: '#b7b7b7',
    gray1: "#E0E0E0",
    gray2: '#B1BAD3',
    gray3: '#314552',
    gray4: '#425A6C',
    orange: '#FFB12E',
    orange1: '#F67603',
    yellow: '#F5C131',
    purple: '#5D28BA',
    popBg: '#2A1D2F',
}
