import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
import i18n from 'i18n-js'
import { Link } from 'react-router-dom'
import { SiteContext } from 'contexts/siteContext'
const footerLogo = require('assets/images/logo.svg')
export default memo(() => {
    const { siteState: { locale } } = useContext(SiteContext)
    const links = [
        { title: 'aboutUs', navigation: '' },
        { title: 'privacyPolicies', navigation: '' },
        { title: 'whitePaper', navigation: '' },
        { title: 'termsConditions', navigation: '' }
    ]
    const moregame = [
        { image: require('assets/images/logo.svg'), navigation: '' },
        { image: require('assets/images/logo.svg'), navigation: '' }
    ]
    const social = [
        { image: require('assets/images/facebook.svg'), navigation: '' },
        { image: require('assets/images/twitter.svg'), navigation: '' },
        { image: require('assets/images/telegram.svg'), navigation: '' },
        { image: require('assets/images/youtube.svg'), navigation: '' }
    ]
    return (
        <FooterWrap>
            <div className="footer_top">
                <div className="footer_top_inner">
                    <div className="fti_left">
                        <div className="footer_logo">
                            <Link to="/">
                                <img src={footerLogo} alt="" />
                            </Link>
                        </div>
                        <div className="footer_game">
                            <span>{i18n.t('moreGames')}:</span>
                            <div className="fg_gameitem">
                                {moregame.map((item, index) => {
                                    return (
                                        <div className="fgg_item" key={index}>
                                            <Link to={item.navigation}>
                                                <img src={item.image} alt="" />
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="footer_info">
                        <div className="fi_links">
                            {links.map((item, index) => {
                                return (
                                    <div className="fil_item" key={index}>
                                        <Link to={item.navigation}>
                                            <span>{i18n.t(item.title)}</span>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="fi_social">
                            {social.map((item, index) => {
                                return (
                                    <div className="fic_item" key={index}>
                                        <a href={item.navigation} target="_blank" rel="noopener noreferrer">
                                            <img src={item.image} alt="" />
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <span>{i18n.t('copyright')} © Luminous. {i18n.t('allRightReserved')}</span>
            </div>
        </FooterWrap>
    )
})

const FooterWrap = memo(styled.div`
    background-color:${Colors.black2};
    .footer_top{
        padding:2.5rem 2.6%;
        .footer_top_inner{
            display:flex;
            align-items:center;
            flex-wrap:wrap;
            justify-content:space-around;
            .fti_left{
                flex:.6;
                display:flex;
                align-items:center;
                justify-content:space-between;
                @media (max-width:1199px){
                    flex:.45;
                    flex-direction:column;
                    align-items:flex-start;
                    justify-content:center;
                }
                @media (max-width:767px){
                    flex:none;
                    width:100%;
                    display:block;
                }
                .footer_logo{
                    flex:.45;
                    text-align:center;
                    @media (max-width:1199px){
                        flex:1;
                        width:100%;
                        text-align:left;
                        margin-bottom:2rem;
                    }
                    @media (max-width:767px){
                        text-align:center;
                    }
                    img{
                        max-width:200px;
                    }
                }
                .footer_game{
                    flex:.45;
                    @media (max-width:1199px){
                        flex:1;
                        width:100%;
                    }
                    @media (max-width:767px){
                        margin-bottom:2rem;
                    }
                    span{
                        display:block;
                        text-align:center;
                        margin-bottom:2rem;
                        @media (max-width:1199px){
                            display:none;
                        }
                    }
                    .fg_gameitem{
                        display:flex;
                        flex-wrap:wrap;
                        align-items:center;
                        justify-content:space-between;
                        .fgg_item{
                            flex:.48;
                            align-items:center;
                            justify-content:center;
                            padding:0.8rem 0;
                            border:solid 4px ${Colors.black3};
                            border-radius:5px;
                            &:hover{
                                animation: glow 800ms ease-out infinite alternate;
                                @keyframes glow {
                                    0% {
                                        border-color: #393;
                                        box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
                                    }	
                                    100% {
                                        border-color: #6f6;
                                        box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000;
                                    }
                                }
                            }
                            a{
                                display:block;
                                text-align:center;
                                img{
                                    max-width:100px;
                                }
                            }
                            
                        }
                    }
                }
            }
            .footer_info{
                flex:.3;
                @media (max-width:1199px){
                    flex:.45;
                }
                @media (max-width:767px){
                    flex:1;
                }
            }
            .footer_info{
                .fi_links{
                    display:flex;
                    flex-wrap:wrap;
                    align-items:center;
                    margin-bottom:2rem;
                    .fil_item{
                        width:50%;
                        &:first-child, &:nth-child(2){
                            order:1;
                        }
                        &:nth-child(3), &:last-child{
                            order:2;
                        }
                        &:hover{
                            a{
                                color:${Colors.orange};
                                &:before{
                                    background-color: ${Colors.orange};
                                }
                            }
                            span{
                                color:${Colors.orange}
                            }
                        }
                        a{
                            display:inline-block;
                            position:relative;
                            padding:5px 0;
                            span{
                                padding-left:1rem;
                            }
                            &:before{
                                content:'';
                                position:absolute;
                                top:50%;
                                left:0;
                                transform:translate(0, -50%);
                                width:5px;
                                height:5px;
                                border-radius:50%;
                                background-color: ${Colors.white};
                            }
                        }
                    }
                }
                .fi_social{
                    display:flex;
                    align-items:center;
                    @media (max-width:767px){
                        justify-content:space-between;
                    }
                    .fic_item{
                        width:43px;
                        height:43px;
                        margin-right:1rem;
                        border-radius:50%;
                        border:solid 1px ${Colors.white};
                        opacity:0.6;
                        transition:all .2s ease-in-out;
                        @media (min-width:768px) and (max-width:991px){
                            width:33px;
                            height:33px;
                        }
                        @media (max-width:767px){
                            width:27px;
                            height:27px;
                        }
                        &:hover{
                            opacity:1;
                        }
                        a{
                            width:100%;
                            height:100%;
                            display:flex;
                            align-items:center;
                            justify-content:center;
                        }
                    }
                }
            }
        }
    }
    .footer_bottom{
        border-top:solid 1px ${Colors.black3};
        padding:1.5rem 0;
        text-align:center;
        color:${Colors.gray1};
        font-style:italic;
    }
`)