import React, { memo, useContext, useEffect, useState } from 'react'
import i18n from 'i18n-js'
import styled from 'styled-components'
import Colors from 'constants/Colors'
import { HeaderContext } from 'contexts/headerContext'
import { TronContext } from 'contexts/tronWeb'
import { contract } from 'contexts/tronConfig'
const userDefaultAvt = require('assets/images/user-avt.svg')
interface AccountProps {
    locale: string
}
export default ({ locale }: AccountProps) => {
    const logged = true;
    const { action } = useContext(TronContext)
    const [username, setUsername] = useState('')
    const { headerState: { showWindow }, changeShowWindow } = useContext(HeaderContext)
    const checkUser = async () => {
        try {
            // let member = await (window as any).tronWeb.contract().at(contract.memberAddress)
            // let user = await member.getUsername(action.address).call()
            // user && setUsername(user)
        } catch (error) {
            console.log('getUserName error', error)
        }
    }
    useEffect(() => {
        checkUser()
    }, [])
    return (
        <AccountWrap active={showWindow === 'user'}>
            {logged ?
                <div id="acc_user_info">
                    <div className="accui_avt"
                        onClick={() => (window as any).innerWidth < 768 && changeShowWindow(showWindow === 'user' ? '' : 'user')}>
                        <img src={userDefaultAvt} alt="" />
                        <div className="arrow_down" />
                    </div>
                    <div className={`accui_detail ${showWindow === 'user' ? 'collapsed' : ''}`}>
                        <span>{`${i18n.t('hello')},`} <b>{username !== '' ? username : i18n.t('guest')}</b></span>
                        <span data-tip={action.address}>
                            {action.address !== '' ? `${action.address.slice(0, 7)}...${action.address.slice(-7)}` : '...'}
                        </span>
                    </div>
                </div>
                :
                <div id="acc_signup_signin">
                    <span className="accss_signin">{i18n.t('signin')}</span>
                    <span>/</span>
                    <span>{i18n.t('signup')}</span>
                </div>
            }
        </AccountWrap>
    )
}

const AccountWrap = memo(styled.div`
    #acc_user_info{
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        position:relative;
        .accui_avt{
            margin-right:1rem;
            @media (max-width:767px){
                margin-right:0;
                display:flex;
                align-items:center;
                position:relative;
                cursor:pointer;
                &:after{
                    content:'';
                    display:${(props: any) => props.active ? 'block' : 'none'};
                    position:absolute;
                    z-index:1;
                    top:calc(100% + 5px);
                    left:9px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-top-width: 0px;
                    border-right-width: 9px;
                    border-bottom-width: 10px;
                    border-left-width: 9px;
                    border-bottom-color: ${Colors.black2};
                    border-right-color: transparent;
                    border-top-color: transparent;
                    border-left-color: transparent;
                }
            }
            .arrow_down{
                display:none;
                @media (max-width:767px){
                    display:block;
                }
            }
            >img{
                width:35px;
                height:35px;
                @media (max-width:767px){
                    width:25px;
                    height:25px;
                }
            }
        }
        .accui_detail{
            @media (max-width:767px){
                height:0;
                position: absolute;
                top:calc(100% + 15px);
                right: 0px;
                text-align: right;
                background:${Colors.black2};
                transition:height .1s ease-in-out;
                overflow:hidden;
                border-radius:5px;
                &.collapsed{
                    height:85px;
                }
            }
            span{
                display:block;
                @media (max-width:767px){
                    padding: 0.9rem;
                    &:first-child{
                        position:relative;
                        &:after{
                            content:'';
                            position:absolute;
                            bottom:0;
                            left:0.9rem;
                            width:calc(100% - 1.8rem);
                            height:1px;
                            background-color:${Colors.black3}
                        }
                    }
                }
            }
        }
    }
    #acc_signup_signin{
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        span{
            &.accss_signin{
                display:flex;
                height:45px;
                width:112px;
                background-color:${Colors.orange};
                border-radius:5px;
                align-items:center;
                justify-content:center;
                cursor:pointer;
            }
            &:nth-child(2){
                padding:0 1rem;
            }
            &:nth-child(3){
                cursor:pointer;
            }
        }
    }
`)