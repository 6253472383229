import React, { memo } from 'react'
import i18n from 'i18n-js'
import styled, { css } from 'styled-components'
import Colors from 'constants/Colors'
const crown = require('assets/images/crown.svg')
const defaultAvt = require('assets/images/default-avatar.svg')
interface TopThreeItemProps {
    position: number;
    name: string;
    avatar?: string;
    totalWage: number;
    coin: string;
    blank?: boolean
}
export default ({ position, name, avatar, totalWage, coin, blank }: TopThreeItemProps) => {
    return (
        <TopThreeItemWrap position={position} title={name}>
            <div className="tti_inner">
                {position !== 1 ?
                    <span className="top_three_positioning">{position}</span>
                    :
                    null
                }
                <div className="tops_avatar">
                    <img src={defaultAvt} alt="" className={`${blank ? 'blank' : ''}`} />
                </div>
                <div className="top_name">
                    <span>{blank ? '--' : name}</span>
                </div>
                <div className="top_balance">
                    <img src={require(`assets/images/coins/${coin}.svg`)} alt="" />
                    <span>{blank ? '--' : i18n.toNumber(totalWage, { precision: 2, strip_insignificant_zeros: true })}</span>
                </div>
            </div>
        </TopThreeItemWrap>
    )
}

const TopThreeItemWrap = memo(styled.div`
    display:flex;
    flex-direction:column;
    flex: 1;
    flex-basis:100%;
    justify-content: ${(props: any) => props.position === 2 ? 'center' : props.position === 3 ? 'flex-end' : 'flex-start'};
    align-items:center;
    padding-bottom:${(props: any) => props.position === 1 ? '2rem' : 0};
    order:${(props: any) => props.position === 1 ? 2 : props.position === 2 ? 1 : 3};
    width:33.3%;
    .tti_inner{
        text-align:center;
        padding:0.5rem 1rem;
        width:calc(100% - 2rem);
        .top_three_positioning{
            margin-bottom:0.5rem;
            display:block;
        }
        .tops_avatar{
            position:relative;
            img{
                width:${(props: any) => props.position === 1 ? '60px' : '45px'};
                &.blank{
                    opacity:.5;
                }
            }
            ${(props: any) => props.position === 1 && css`
                &:before{
                    content:'';
                    position:absolute;
                    height: 1rem;
                    width:100%;
                    top:-.9rem;
                    left:0;
                    background:url(${crown});
                    background-repeat:no-repeat;
                    background-position:center;
                };
            `
    }}
        .top_balance{
            display:flex;
            align-items:center;
            justify-content:center;
            span{
                font-weight:bold;
                color:${Colors.orange};
            }
        }
        .top_name{
            margin:0.5rem 0 0.8rem;
            span{
                display:block;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
                font-weight:400;
            }
        }
    }
    
`)