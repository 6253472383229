import { createGlobalStyle } from 'styled-components'
import Colors from 'constants/Colors'
const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font-family: 'Roboto', sans-serif;
        /* font-family:'Digital7'; */
        vertical-align: baseline;
        line-height: inherit;
    }
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    #root{
        width:100%;
        height:100%;
    }
    body {
        line-height: 1;
    }
    ol, ul, li {
        list-style: none;
    } 
    span, p{
        line-height:1.5;
        color:${Colors.white};
        font-weight:300;
    }
    div{
        display:block;
    }
    a{
        color:${Colors.white};
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    *:focus {
        outline: none;
    }
    ::-webkit-scrollbar {
        width: 5px;
        border-left:solid 1px #1A2C38;
        border-radius:1.5px;
        @media (max-width:767px){
            width:3px;
        }
    }
    ::-webkit-scrollbar-track {
        background:rgba(0, 0, 0, 0.5);
        border-radius:1.5px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${Colors.gray3};
        border-radius:1.5px;
    }
    body,html {
        height: 100%;
        font-size: 16px;
        font-family: inherit;
        color:${Colors.white};
        /* @media (min-width: 1200px) and (max-width:1599px) {
            font-size:14px;
        } */
        @media (max-width:1199px) {
            font-size:14px;
        }
    }
    .grecaptcha-badge {
        display: none !important
    }
    button {
        cursor: pointer;
    }
    img {
        max-width: 100%;
    }
    textarea, input {
        font-family: 'Roboto', sans-serif;
    }
    a {
        text-decoration: none;
    }
    .hidden {
        display: none;
    }
    .disable {
        cursor: not-allowed !important;
        opacity: 0.4;
    }
    .text-danger {
        color: ${Colors.danger};
    }
    .uppercase {
        text-transform: uppercase;
    }
    h1,h2,h4,h5,h6{
        color:${Colors.white};
    }
    h1{
        line-height: 1.5;
        font-weight: 700;
        font-size: 4rem;
    }
    h2{
        font-size:1.5rem;
        line-height:1.5;
        font-weight:700;
    }
    h3{
        font-weight: 600;
        font-size: 1.2rem;
        line-height:1.5;
    }
    button {
        transition: all 0.3s;
        &.save-change {
            background: #3ca2fa;
            border: none;
            border-radius: 5px;
            padding: 10px 20px;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
            @media (min-width: 992px) and (max-width: 1199px) {
                padding: 7px 20px;
            }
            @media (max-width: 991px) {
                padding: 5px 15px;
            }
            &:disabled {
                background: #bbdfff;
                cursor: not-allowed;
            }
            &:hover {
                background: #5e8db7;
                color: #bbdfff;
            }
        }
    }
    .Toastify__toast-container{
        >div{
            width:100%;
        }
    }
    .form_body{
        .form_input{
            margin-bottom:1rem;
            span{
                display:block;
                color:${Colors.black};
                font-size:0.9rem;
                font-weight:600
            }
            input{
                border: solid 2px rgba(128,163,182,0.22);
                border-radius:4px;
                padding:0 0.8rem;
                height:40px;
                transition: border 0.25s ease 0s, box-shadow 0.25s ease 0s;
                &:focus{
                    border-color: ${Colors.black};
                }
            }
        }
    }
    .form_button{
        button{
            padding:0.8rem 0.9rem;
            background: ${Colors.blue};
            color:${Colors.white};
            border-radius:4px;
            transition: all 0.25s;
            border:none;
            font: 600 1rem 'Roboto';
            @media (max-width:767px){
                padding:0.5rem 0.7rem;
            }
            &:disabled {
                opacity:.4;
                pointer-events:none;
            }
            &:hover {
                background: ${Colors.blue};
            }
        }
    }
    .body_padding{
        padding: 0 2.6%;
    }
    .arrow_down{
        width: 0;
        height: 0;
        margin-left:5px;
        border-style: solid;
        border-top-width: 6px;
        border-right-width: 5px;
        border-bottom-width: 0;
        border-left-width: 5px;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top-color: ${Colors.white};
        border-left-color: transparent;
    }
    .block{
        margin: 50px 0;
    }
    .block_label{
        width:100%;
        span{
            font-size:1.25rem;
            height:50px;
            font-weight:bold;
            position:relative;
            align-items:center;
            display:flex;
            &:after{
                content:'';
                width:100px;
                height:2px;
                bottom:0;
                left:0;
                position:absolute;
                background-color:${Colors.orange};
            }
        }
    }
    .block_content{
        padding:1rem 0;
    }
    .addblock_notification{
        display:block;
        border-radius:5px;
        font-size:0.8rem;
        color:${Colors.gray1};
        padding:0.5rem;
        background-color:rgba(0,0,0,.2)
    }
    .blur_bg{
        position:fixed;
        z-index:1;
        bottom:0;
        left:0;
        height:calc(100vh - 70px);
        width:100vw;
        opacity:0;
        visibility:hidden;
        background: rgba(14,18,36,.7);
        transition: all 0.2s cubic-bezier(0.06, 0.33, 0, 0.99) 0s;
        @media (max-width:767px){
            height:calc(100vh - 60px);
        }
        &.show{
            opacity:1;
            visibility:visible;
        }
    }
    .aside_inner{
        padding:2rem;
        background:${Colors.black1};
        @media (max-width:767px){
            padding:1rem;
        }
    }
    .coin_image{
        width:15px;
    }
`
export default GlobalStyle;