import React, { lazy, Suspense } from 'react'
import { Route, Switch } from "react-router-dom";
import Loading from 'components/common/loading'
import Colors from 'constants/Colors'
const Home = lazy(() => import("containers/home"));
const LuckyDice = lazy(() => import("containers/games/luckyDice"));
export default () => {
    return (
        <Suspense fallback={<Loading color={Colors.blue} />}>
            <Switch>
                {/* <Route exact path="/" component={Home} /> */}
                <Route path="/lucky-dice" component={LuckyDice} />
                <Route path="/" component={Home} />
            </Switch>
        </Suspense>
    )

}