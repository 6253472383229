import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
import { contract } from 'contexts/tronConfig'
import i18n from 'i18n-js'
const defaultAvt = require('assets/images/default-avatar.svg')
interface TopTenItemProps {
    position: number;
    name: string;
    avatar: string;
    totalWage: number;
    coin: string;
}
export default memo(({ position, name, avatar, totalWage, coin }: TopTenItemProps) => {
    return (
        <TopTenItemWrap>
            <div className="topten_position">
                <span>{position}</span>
            </div>
            <div className="topten_detail" title={name}>
                <div className="ttd_left">
                    <img src={avatar !== '' ? avatar : defaultAvt} alt="" />
                    <span>{name}</span>
                </div>
                <div className="ttd_right">
                    <span>{i18n.toNumber(totalWage, { precision: 2, strip_insignificant_zeros: true })}</span>
                    <img src={require(`assets/images/coins/${coin !== '' ? coin : 'default-coin'}.svg`)} alt="" />
                </div>
            </div>
        </TopTenItemWrap>
    )
})
const TopTenItemWrap = memo(styled.div`
    display:flex;
    align-items:center;
    .topten_position{
        margin-right:1rem;
        width:25px;
    }
    .topten_detail{
        background:${Colors.black2};
        border-radius:50px;
        display:flex;
        flex:1;
        align-items:center;
        justify-content:space-between;
        padding:6px 1rem;
        width: calc(90% - 2rem);
        .ttd_left{
            display:flex;
            align-items:center;
            max-width:60%;
            span{
                display:block;
                width:100%;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:no-wrap;
            }
            img{
                width:25px;
                height:25px;
                border-radius:50%;
                margin-right:1rem;
            }
        }
        .ttd_right{
            display:flex;
            align-items:center;
            span{
                font-weight:400;
                color:${Colors.orange};
            }
        }
    }
`)