import React, { memo, useState, useContext, useEffect } from 'react'
import i18n from 'i18n-js'
import styled from 'styled-components'
import TopTenItem from 'components/common/leaderBoard/topTenItem'
import TopThreeItem from 'components/common/leaderBoard/topThreeItem'
import { SiteContext } from 'contexts/siteContext'
import { useApolloClient, gql } from "@apollo/client";
const GET_LEADERBOARD = gql`
query getLeaderBoard($quantity: Int!) {
    dice_leaderboard_get(quantity: $quantity) {
        address
        totalWager
        currentBet
    }
}
`
export default () => {
    const { siteState: { locale } } = useContext(SiteContext)
    const currency = 'eth'
    const [topTen, setTopTen] = useState([] as any)
    const client = useApolloClient()
    const getLeaderData = () => {
        try {
            const query = client.watchQuery({
                query: GET_LEADERBOARD,
                variables: {
                    quantity: 10
                }
            })
            const querySub = query.subscribe(
                ({ data: { dice_leaderboard_get } }) => {
                    // console.log('data leader board', dice_leaderboard_get)
                    if (dice_leaderboard_get) {
                        let list = [] as any
                        for (let i = 0; i < dice_leaderboard_get.length; i++) {
                            list.push({
                                name: dice_leaderboard_get[i].address,
                                totalWage: dice_leaderboard_get[i].totalWager / 10 ** 6,
                                avatar: ''
                            })
                        }
                        setTopTen(list)
                    }
                }
            )
            return querySub
        } catch (error) {
            console.log('error get leaderboard data', error)
        }
    }
    useEffect(() => {
        const query = getLeaderData();
        return () => query && query.unsubscribe()
    }, [])
    // console.log('topTen', topTen)
    return (
        <LeaderBoardWrap>
            <div className="block_label">
                <span>{i18n.t('leaderBoard')}</span>
            </div>
            <div className="block_content">
                <div className="leader_top_three">
                    {topTen.map((item, index) => {
                        if (index < 3) {
                            return (
                                <TopThreeItem
                                    key={index}
                                    position={index + 1}
                                    name={item.name}
                                    avatar={item.avatar}
                                    totalWage={item.totalWage}
                                    coin={currency}
                                />
                            )
                        }
                    })}
                    {topTen.length === 2 ?
                        <TopThreeItem
                            position={3}
                            name=""
                            avatar=""
                            totalWage={0}
                            coin={currency}
                            blank={true}
                        />
                        :
                        null
                    }
                </div>
                <div className="leader_top_ten">
                    {topTen.map((item, index) => {
                        if (index > 2) {
                            return (
                                <TopTenItem
                                    key={index}
                                    position={index + 1}
                                    name={item.name}
                                    avatar={item.avatar}
                                    totalWage={item.totalWage}
                                    coin={currency}
                                />
                            )
                        }
                        else return null
                    })}
                </div>
            </div>
        </LeaderBoardWrap>
    )
}

const LeaderBoardWrap = memo(styled.div`
    .leader_top_three{
        display:flex;
    }
    img{
        width:20px;
    }
    .leader_top_ten{
        >div{
            &:not(:last-child){
                margin-bottom:6px;
            }
        }
    }
`)