import React, { memo, useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
import { TronContext } from 'contexts/tronWeb'
import i18n from 'i18n-js'
const coin = {
    lumi: require('assets/images/coins/coin_lumi.svg')
}
interface GameBalanceProps {
    sticky: boolean;
}
export default ({ sticky }: GameBalanceProps) => {
    const { data, action, tronWeb } = useContext(TronContext)
    const [balance, setBalance] = useState(0)
    const watchEvent = async () => {
        try {
            action.dice && action.dice.BetSettle().watch((err, event) => {
                if (err) return console.error('Error with "Message" event:', err);
                let add = tronWeb.address.fromHex(event.result.user)
                if (add === action.address) {
                    getBalance()
                }
            });
        } catch (error) {
            console.log('errorX', error)
        }
    }
    useEffect(() => {
        watchEvent()
        getBalance()
    }, [action.address, action.dice])

    const getBalance = async () => {
        if (data?.lumi !== undefined) {
            let balance = await data.lumi.balanceOf(action.address).call()
            setBalance(Number(balance / 10 ** 6))
        }
    }
    return (
        <GameBalance sticky={sticky}>
            <div className="balance_wrap">
                <div className="bw_left">
                    <img src={coin.lumi} alt="" />
                    <span>LUMI</span>
                </div>
                <div className="bw_right">
                    <span>{i18n.toNumber(balance, { precision: balance > 100 ? 2 : 6 })}</span>
                </div>
            </div>

        </GameBalance>
    )
}

const GameBalance = memo(styled.div`
    height:44px;
    display:flex;
    padding:0 1rem;
    border-radius:5px;
    background-color:${(props: any) => props.sticky ? Colors.black2 : Colors.black3};
    @media (max-width:767px){
        height:35px;
        padding:0 0.7rem;
    }
    .balance_wrap{
        display:flex;
        align-items:center;
        .bw_left{
            display:flex;
            align-items:center;
            margin-right:1rem;
            height:100%;
            border-right:solid 1px;
            border-right-color: ${(props: any) => props.sticky ? Colors.black3 : Colors.black2};
            @media (max-width:767px){
                width:20px;
                display:none;
            }
            img{
                margin-right:5px;
                width:25px;
            }
            span{
                padding-right:1rem;
                @media (max-width:480px){
                    display:none;
                }
            }
        }
    }
`)