import React, { memo } from 'react'
import styled from 'styled-components'
interface CurrentLangImgProps {
    locale: string;
}
export default memo(({ locale }: CurrentLangImgProps) => {
    return (
        <CurrentLangImgWrap>
            <img src={require(`assets/images/${locale}.svg`)} alt="" />
        </CurrentLangImgWrap>
    )
})
const CurrentLangImgWrap = memo(styled.div`
    width:35px;
    height:35px;
    position:relative;
    border-radius:50%;
    overflow:hidden;
    @media (max-width:767px){
        width:25px;
        height:25px;
    }
    img{
        height:35px;
        max-width:inherit;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        @media (max-width:767px){
            height:25px;
        }
    }
`)