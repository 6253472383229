import React, { memo, useContext } from 'react'
import i18n from 'i18n-js'
import styled from 'styled-components'
import ChatSend from 'components/common/chat/chatSend'
import ChatHistory from 'components/common/chat/chatHistory'
import { SiteContext } from 'contexts/siteContext'

export default () => {
    const { siteState: { locale } } = useContext(SiteContext)
    return (
        <ChatWrap>
            <div className="block_label">
                <span>{i18n.t('chat')}</span>
            </div>
            <div className="block_content">
                <div className="chat_inner">
                    <div className="ci_scroller">
                        <ChatHistory />
                    </div>
                    <ChatSend />
                </div>
            </div>
        </ChatWrap>
    )
}

const ChatWrap = memo(styled.div`
    height:100%;
    .block_content{
        padding:0;
        height:calc(100% - 50px);
        .chat_inner{
            height:calc(100% - 1rem);
            padding:1rem 0 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            .ci_scroller{
                height:100%;
                overflow-y:scroll;
                margin-bottom:2rem;
            }
        }
    }
`)