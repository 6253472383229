import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SiteContextWrapper from "contexts/siteContext";
import Body from "components/common/body";
import GlobalStyle from "components/common/globalStyle";
import TronContext from 'contexts/tronWeb';
import Apollo from './Apollo'
export default () => {
  return (
    <SiteContextWrapper>
      <TronContext>
        <Apollo>
          <GlobalStyle />
          <Router >
            <Body />
          </Router>
        </Apollo>
      </TronContext>
    </SiteContextWrapper>
  );
}
