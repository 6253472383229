import React, { memo } from 'react'
import styled from 'styled-components'
import Colors from 'constants/Colors'
const gameImg = {
    'megabit': require('assets/images/megabit-icon.svg'),
    'dice': require('assets/images/dice-icon.svg')
}
interface ActivitiesItemProps {
    type: string;
    content: string
}
export default memo(({ type, content }: ActivitiesItemProps) => {
    return (
        <ActivitiesItemWrap>
            <div className="activities_item_inner">
                <div className={`aii_icon ${type}`}>
                    <img src={gameImg[type]} alt="" />
                </div>
                <span>{content}</span>
            </div>
        </ActivitiesItemWrap>
    )
})

const ActivitiesItemWrap = memo(styled.div`
    margin-bottom:1.8rem;
    .activities_item_inner{
        display:flex;
        .aii_icon{
            width:30px;
            height:30px;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-right:1rem;
            border-radius:5px;
            &.megabit{
                background-color:${Colors.blue1};
            }
            &.dice{
                background-color:${Colors.purple};
            }
            img{
                width:1.5rem;
            }
        }
        span{
            line-height:30px;
            /* transform:  matrix(-1, 0, 0, 1, 0, 0); */
        }
    }
`)
