import React, { memo, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from 'components/common/header/logo'
import Language from 'components/common/language'
import Account from 'components/common/header/account'
import { SiteContext } from 'contexts/siteContext'
import { useLocation } from "react-router-dom";
import Colors from 'constants/Colors'
import LeftAside from 'components/common/header/leftAside'
import RightAside from 'components/common/header/rightAside'
import HeaderContextWrapper from "contexts/headerContext";
import BalanceGame from 'components/common/header/balanceGame'
interface HeaderProps {
    bodyRef: any
}
export default ({ bodyRef }: HeaderProps) => {
    const { siteState: { locale } } = useContext(SiteContext)
    let currentPath = useLocation();
    const [sticky, setSticky] = useState(false)
    const handleScroll = (e) => {
        if (window.innerWidth > 480) {
            if (e.target.scrollTop > 68) {
                setSticky(true)
            }
            else { setSticky(false) }
        }
        else {
            if (e.target.scrollTop > 48) {
                setSticky(true)
            }
            else { setSticky(false) }
        }

        return e.target.scrollTop
    }
    useEffect(() => {
        // console.log('HHH', bodyRef.current.clientHeight)
        let current = bodyRef.current
        current.addEventListener('scroll', handleScroll);
        return () => current.removeEventListener('scroll', handleScroll);
    }, [bodyRef]);
    return (
        <HeaderContextWrapper>
            <HeaderWrap sticky={sticky} home={currentPath.pathname === '/' || currentPath.pathname === ''}>
                <div id="header_inner" className="body_padding">
                    <div id="hi_left">
                        {currentPath.pathname === '/' || currentPath.pathname === '' ?
                            null :
                            <LeftAside />
                        }
                        <Logo />
                    </div>
                    <div id="hi_center">
                        <BalanceGame sticky={sticky}/>
                    </div>
                    <div id="hi_right">
                        <Language />
                        <Account locale={locale} />
                        {currentPath.pathname === '/' || currentPath.pathname === '' ?
                            null :
                            <RightAside />
                        }
                    </div>
                </div>
            </HeaderWrap>
        </HeaderContextWrapper>
    )
}

const HeaderWrap = memo(styled.div`
    position:${(props: any) => props.sticky ? 'fixed' : props.home ? 'absolute' : 'relative'};
    background-color:${(props: any) => props.sticky ? Colors.black3 : 'transparent'};
    box-shadow:${(props: any) => props.sticky ? '0px 3px 6px rgba(34,57,72,.16)' : 'transparent'};
    top:0;
    left:0;
    height:70px;
    width:100%;
    z-index:2;
    @media (max-width:767px){
        background:${Colors.black1};
        height:60px;
    }
    @media (max-width:480px){
        height:50px;
    }
    #header_inner{
        display:flex;
        align-items:center;
        justify-content:space-between;
        height:100%;
        #hi_left{
            display:flex;
            align-items:center;
        }
        #hi_center{
            @media (max-width:400px){
                margin-right:5px;
            }
        }
        #hi_right{
            display:flex;
            align-items:center;
        }
    }
`)