import React, { memo } from 'react'
import Colors from 'constants/Colors'
import styled from 'styled-components'
export default memo(() => {
    return (
        <LoadingScreenWrap>
            <div id="loader_screen"></div>
        </LoadingScreenWrap>
    )
})

const LoadingScreenWrap = memo(styled.div`
    position:fixed;
    z-index:9;
    width:100vw;
    height:100vh;
    left:0;
    top:0;
    display:flex;
    align-items:center;
    justify-content:center;
    background:${Colors.black2};
    #loader_screen{
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 3px solid transparent;
        /* border-top-color: #9370DB; */
        animation: spin 1.35s linear infinite, glow1 800ms ease-out infinite alternate;
        &:before{
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            /* border-top-color: #BA55D3; */
            animation: spin 2s linear infinite, glow2 800ms ease-out infinite alternate;
        }
        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            /* border-top-color: ; */
            animation: spin 0.85s linear infinite, glow3 800ms ease-out infinite alternate;
        }
        @keyframes spin {
            0%   {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes glow3 {
            0% {
                border-top-color: ${Colors.orange};
                /* box-shadow: 0 0 5px rgba(0,255,0,0), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000; */
            }	
            100% {
                border-top-color: #FFD62C;
                /* box-shadow: 0 0 20px rgba(0,255,0,0), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000; */
            }
        }
        @keyframes glow2 {
            0% {
                border-top-color: #393;
                /* box-shadow: 0 0 5px rgba(0,255,0,0), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000; */
            }	
            100% {
                border-top-color: #6f6;
                /* box-shadow: 0 0 20px rgba(0,255,0,0), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000; */
            }
        }
        @keyframes glow1 {
            0% {
                border-top-color: #396AEF;
                /* box-shadow: 0 0 5px rgba(0,255,0,0), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000; */
            }	
            100% {
                border-top-color: #34B9EA;
                /* box-shadow: 0 0 20px rgba(0,255,0,0), inset 0 0 10px rgba(0,255,0,.4), 0 2px 0 #000; */
            }
        }
    }
`)