import React, { memo, useState } from 'react'
import styled from 'styled-components'
import ChatItem from 'components/common/chat/chatItem'
export default () => {
    //TODO Chat history
    const [chatData, setChatData] = useState([
        {user:'Demo', content:'Chat is will coming soon'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Long', content:'*éo hiểu sao!!!'},
    //     {user:'Dungmama', content:'Dạo này cây vườn nhà trông có vẻ xanh xao thiếu dinh dưỡng'},
    //     {user:'Trang', content:'Haiz, lại mất ngủ'},
    //     {user:'Long', content:'*éo hiểu sao!!!'}
    ] as any)
    return (
        <ChatHistoryWrap>
            <div className="ch_inner">
                {chatData.map((item, index) => {
                    return (
                        <ChatItem key={index} user={item.user} content={item.content} />
                    )
                })}
            </div>
        </ChatHistoryWrap>
    )
}

const ChatHistoryWrap = memo(styled.div`
    display:flex;
    flex-grow:2;
    .ch_inner{
        height:100%;
        width:100%;
    }
`)