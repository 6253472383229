// const node = "https://api.tronstack.io";
const node = "https://api.trongrid.io";
const contract = {
  // memberAddress: "TNDGkMzv7GsUS5jUFRMwnN6Z9hvTHj3ZPK"
  // memberAddress: 'THyaGysy7iFDzh4azARH9FESahiZxtV3iG',
  memberAddress: "TNDGkMzv7GsUS5jUFRMwnN6Z9hvTHj3ZPK",
  diceAddress: "TNee2igNv8WKsngZMmNwiHXSEacSHkYxZM",
  token: "TDBNKiYQ8yfJtT5MDP3byu7f1npJuG2DBN",
};
export { node, contract };
