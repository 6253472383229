import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import AsideChat from 'components/common/header/rightAside/asideChat'
import { HeaderContext } from 'contexts/headerContext'
const chatImg = require('assets/images/chat.svg')
export default () => {
    const { headerState: { showWindow }, changeShowWindow } = useContext(HeaderContext)
    const setShow = () => {
        changeShowWindow('')
    }
    return (
        <ChatWrap>
            <img src={chatImg} alt="" onClick={() => changeShowWindow(showWindow === 'chat' ? '' : 'chat')} />
            {(window as any).innerWidth < 1200 ?
            <AsideChat showWindow={showWindow} setShow={setShow} />
            :
                null
            }
        </ChatWrap>
    )
}

const ChatWrap = memo(styled.div`
    margin-left:1rem;
    position:relative;
    >img {
        height:25px;
        display:none;
        @media (max-width:1199px){
            display:block;
        }
    }
`)